import apiClient from '@/services/axios'

class ViewScreenService {
  // VIEWSCREENS

  async getViewscreenTabs(name) {
    const searchParams = new URLSearchParams()
    if (name) {
      searchParams.append('name', String(name))
    }

    const url = `/admin/viewscreens?${searchParams}`
    return apiClient.get(url)
  }

  async getViewscreenTab(id, langId, bundle_name) {
    const searchParams = new URLSearchParams()
    if (langId) {
      searchParams.append('lang_id', String(langId))
    }
    if (bundle_name) {
      searchParams.append('bundle_name', String(bundle_name))
    }
    const url = `/admin/viewscreens/${id}?${searchParams}`
    return apiClient.get(url)
  }

  async createViewscreenTab(data) {
    const url = '/admin/viewscreens'
    return apiClient.post(url, data)
  }

  async updateViewscreenTab(id, data) {
    const url = `/admin/viewscreens/${id}`
    return apiClient.patch(url, data)
  }

  async deleteViewscreenTab(id) {
    const url = `/admin/viewscreens/${id}`
    return apiClient.delete(url)
  }

  async sortViewscreens(data) {
    const url = '/admin/viewscreens/sort'
    return apiClient.post(url, data)
  }

  async publishViewscreenSections(viewscreenId) {
    const url = `/admin/viewscreens/${viewscreenId}/sections/publish-all`
    return apiClient.post(url)
  }

  // SECTIONS
  async getViewscreenSection(viewscreenId, sectionId) {
    const url = `/admin/viewscreens/${viewscreenId}/sections/${sectionId}`
    return apiClient.get(url)
  }

  async createViewscreenSection(id, data) {
    const url = `/admin/viewscreens/${id}/sections`
    return apiClient.post(url, data)
  }

  async updateViewscreenSection(viewscreenId, sectionId, data) {
    const url = `/admin/viewscreens/${viewscreenId}/sections/${sectionId}`
    return apiClient.patch(url, data)
  }

  async deleteViewscreenSection(viewscreenId, sectionId) {
    const url = `/admin/viewscreens/${viewscreenId}/sections/${sectionId}`
    return apiClient.delete(url)
  }

  async addViewscreenSectionItem(sectionId, itemId) {
    const url = `/admin/sections/${sectionId}/items/${itemId}`
    return apiClient.post(url)
  }

  async deleteViewscreenSectionItem(sectionId, itemId) {
    const url = `/admin/sections/${sectionId}/items/${itemId}`
    return apiClient.delete(url)
  }

  async updateMatchGroupLimit(sectionId, data) {
    const url = `/admin/sections/${sectionId}/limit`
    return apiClient.post(url, data)
  }

  async sortSectionItems(sectionId, data) {
    const url = `/admin/sections/${sectionId}/items/sort`
    return apiClient.post(url, data)
  }

  async sortSections(data) {
    const url = '/admin/sections/sort'
    return apiClient.post(url, data)
  }

  // INFORMERS
  async getInformers() {
    const url = '/admin/informers'
    return apiClient.get(url)
  }

  async createInformer(data) {
    const url = '/admin/informers'
    return apiClient.post(url, data)
  }

  async updateInformer(id, data) {
    const url = `/admin/informers/${id}`
    return apiClient.put(url, data)
  }

  // SEGMENTS

  async getSegments(login, page, perPage) {
    const searchParams = new URLSearchParams()
    if (login) {
      searchParams.append('name', String(login))
    }
    if (page) {
      searchParams.append('page', String(page))
    }
    if (perPage) {
      searchParams.append('perPage', String(perPage))
    }
    const url = `/admin/segments?${searchParams}`
    return apiClient.get(url)
  }

  async getSegment(id) {
    const url = `/admin/segments/${id}`
    return apiClient.get(url)
  }

  async createSegment(data) {
    const url = '/admin/segments'
    return apiClient.post(url, data)
  }

  async updateSegment(id, data) {
    const url = `/admin/segments/${id}`
    return apiClient.patch(url, data)
  }

  async deleteSegment(id) {
    const url = `/admin/segments/${id}`
    return apiClient.delete(url)
  }

  async getSegmentAccounts(id, login, page, perPage) {
    const searchParams = new URLSearchParams()
    if (login) {
      searchParams.append('login', String(login))
    }
    if (page) {
      searchParams.append('page', String(page))
    }
    if (perPage) {
      searchParams.append('results', String(perPage))
    }
    const url = `/admin/segments/${id}/accounts?${searchParams}`
    return apiClient.get(url)
  }

  async addSegmentAccount(segmentId, data) {
    const url = `/admin/segments/${segmentId}/accounts`
    return apiClient.post(url, data)
  }

  async deleteSegmentAccount(segmentId, userId) {
    const url = `/admin/segments/${segmentId}/accounts/${userId}`
    return apiClient.delete(url)
  }

  async deleteSegmentAccounts(segmentId, data) {
    const url = `/admin/segments/${segmentId}/accounts`
    return apiClient.delete(url, {
      params: { data },
    })
  }
}
export default new ViewScreenService()
