<template>
  <div class="promoImages-section">
    <a-card>
      <template #title>
        <div class="mt-1">
          <div class="d-flex font-size-20">
            <span v-if="sectionTitle" class="section-title" :class="{ 'hidden-title': !showLabel }" >
              {{ sectionTitle }}
            </span>
          </div>
        </div>
      </template>
      <template #extra>
        <div class="d-flex align-items-center section-extra font-size-20 text-right">
          <div class="mt-0 d-flex align-items-center">
            <span>
              <a-button :disabled="!isEditAvailable" type="primary" icon="plus" @click="createPromoSection">
                Add
              </a-button>
            </span>
            <div v-if="isEditAvailable" class="mr-auto ml-3" style="margin-top: 0.1rem">
              <a-tooltip title="Edit section" placement="bottom">
                <a-icon @click="editSection" type="edit" class="main-tabs__sections__icons text-primary" />
              </a-tooltip>
            </div>
          </div>
          <div class="mr-auto ml-3">
            <span class="">
              <a-tooltip v-if="index < maxIndex - 1 && isEditAvailable" title="Move down" placement="bottom">
                <a-icon @click="moveDown" type="down" class="movable move-down text-primary" />
              </a-tooltip>
              <a-icon v-else type="down" class="movable text-gray-5" />
            </span>
            <span class="ml-3">
              <a-tooltip v-if="index > 0 && isEditAvailable" title="Move up" placement="bottom">
                <a-icon @click="moveUp" type="up" class="movable move-up text-primary" />
              </a-tooltip>
              <a-icon v-else type="up" class="movable text-gray-5" />
            </span>
          </div>
          <a href="javascript:" class="text-primary ml-3" @click.stop="changeStatusSection">
            <a-switch v-model="isActiveSectionStatus" class="ml-3" :disabled="!isEditAvailable" />
          </a>
          <div v-if="isEditAvailable" @click="toggleCarousel" class="ml-3 dropDown-icon" :class="{ 'spin': showAsList }">
            <DropDownIcon />
          </div>
        </div>
      </template>
      <template #cover>
        <transition-group tag="div" class="carousel-container" type="transition" name="flip-grid">
          <a-carousel v-if="!showAsList" arrows :slidesToShow="computedSlidesToShow" :slidesToScroll="1" :dots="false"
            variableWidth :infinite="false" :key="1">
            <div style="z-index: 1" slot="prevArrow"><a-icon type="left" class="text-primary"></a-icon></div>
            <div style="z-index: 1" slot="nextArrow"><a-icon type="right" class="text-primary"></a-icon></div>
            <div v-for="(promoItem, index) in promoImages" :key="index" class="promo-container"
              :style="{ width: promoItem.promoType === 'banner' ? '358px' : '195px' }">
              <div v-if="promoItem.promoType === 'banner'" class="promo-item mr-3" :class="{'disabled': !promoItem.isActive}">
                <div class="img-container">
                  <img :src="promoItem.imagePath" class="banner-img" />
                  <div v-if="isEditAvailable" class="overlay">
                    <div class="edit-banner pointer" @click="editPromo(promoItem.promoType, promoItem.promoId)">
                      <a-icon type="edit" class="text-white" />
                    </div>
                    <a-popconfirm placement="right" title="Do you really want to delete it?" class="delete-banner pointer"
                      @confirm="deletePromo(promoItem.promoId)">
                      <a-icon type="delete" class="text-white" />
                    </a-popconfirm>
                  </div>
                </div>
                <a-tooltip :title="promoItem.name" placement="top">
                  <span class="promo-title">{{ promoItem.name }}</span>
                </a-tooltip>
              </div>
              <div v-else class="promo-item mr-3" :class="{'disabled': !promoItem.is_active}">
                <div class="img-container">
                  <img :src="promoItem?.preview?.[selectedLang]?.[0].path" class="story-img" />
                  <div class="overlay">
                    <div class="edit-banner pointer" @click="editPromo(promoItem.promoType, promoItem.promoId)">
                      <a-icon type="edit" class="text-white" />
                    </div>
                    <a-popconfirm placement="right" title="Do you really want to delete it?" class="delete-banner pointer"
                      @confirm="deletePromo(promoItem.promoId)">
                      <a-icon type="delete" class="text-white" />
                    </a-popconfirm>
                  </div>
                </div>
                <a-tooltip :title="promoItem.name" placement="top"><span class="promo-title">{{
                  promoItem.name }}</span></a-tooltip>
              </div>
            </div>
          </a-carousel>
          <draggable v-else v-model="promoImages" v-bind="dragOptions" @start="drag = true" @end="sortPromo" tag="div"
            :key="2">
            <transition-group>
              <div v-for="(promoItem, index) in promoImages" :key="index" class="d-inline-block"
                style="margin: 0 2rem 2rem 0;">
                <div v-if="promoItem" class="promo-item" :class="{'disabled': promoItem.promoType === 'banner' ? !promoItem.isActive : !promoItem.is_active}">
                  <div class="img-container">
                    <img v-if="promoItem.promoType === 'banner'" :src="promoItem.imagePath" class="banner-img" />
                    <img v-if="promoItem.promoType === 'story'" :src="promoItem?.preview?.[selectedLang]?.[0].path"
                      class="story-img" />
                    <div class="overlay">
                      <div class="edit-banner pointer" @click="editPromo(promoItem.promoType, promoItem.promoId)">
                        <a-icon type="edit" class="text-white" />
                      </div>
                      <a-popconfirm placement="right" title="Do you really want to delete it?" class="delete-banner pointer"
                        @confirm="deletePromo(promoItem.promoId)">
                        <a-icon type="delete" class="text-white" />
                      </a-popconfirm>
                    </div>
                  </div>
                  <span class="promo-title">{{ promoItem.name }}</span>
                </div>
              </div>
            </transition-group>
          </draggable>
        </transition-group>
      </template>
    </a-card>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import DropDownIcon from '@/components/custom/icons/dropDownIcon.vue'
import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'promoImagesSection',
  props: ['promoImagesData', 'index', 'maxIndex', 'selectedLang', 'langs', 'showLabel', 'isActive', 'localizations', 'sectionId', 'activeTabId', 'isEditAvailable'],
  components: {
    draggable,
    DropDownIcon,
  },
  data: () => ({
    isActiveSectionStatus: false,
    promoImages: null,
    drag: false,
    showAsList: false,
    windowWidth: window.innerWidth,
  }),
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
    promoImagesData(value) {
      this.promoImages = value
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    selectedLangId() {
      return this.langs.find(lang => this.selectedLang === lang.shortcut).value
    },
    sectionTitle() {
      const localization = this.localizations.find(section => Number(section.lang_id) === Number(this.selectedLangId))
      return localization ? localization.value : null
    },
    computedSlidesToShow() {
      switch (true) {
        case this.windowWidth >= 2000:
          return 8
        case this.windowWidth >= 1920:
          return 7
        case this.windowWidth >= 1200:
          return 4
        case this.windowWidth <= 1200:
          return 3
        default:
          return 5
      }
    },
  },
  created() {
    this.promoImages = this.promoImagesData
    this.isActiveSectionStatus = this.isActive
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    editPromo(promoType, promoId) {
      this.$router.push(`/promo-images/${promoType}/${promoId}/${this.activeTabId}`)
    },
    createPromoSection() {
      this.$router.push(`/promo-images/create-promo/${this.activeTabId}/${this.sectionId}`)
    },
    toggleCarousel() {
      this.showAsList = !this.showAsList
    },
    editSection() {
      this.$emit('editSection', this.sectionId)
    },
    moveDown() {
      this.$emit('moveDownSection', this.sectionId, 1)
    },
    moveUp() {
      this.$emit('moveUpSection', this.sectionId, -1)
    },
    changeStatusSection() {
      this.$emit('changeStatusSection', this.sectionId, this.isActiveSectionStatus)
    },
    async sortPromo() {
      const promoSort = this.promoImages.map((promo, index, promoArr) => {
        return {
          id: promo.promoId,
          sort: promoArr.length - index,
        }
      })
      const sort = { sort: promoSort }
      try {
        await ViewscreenService.sortSectionItems(this.sectionId, sort)
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
        this.$emit('toggleIsPublished')
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
    async deletePromo(promoId) {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteViewscreenSectionItem(this.sectionId, promoId)
        if (response.data.success === true) {
          const deletedPromoIndex = this.promoImages.findIndex(promoImage => promoImage.promoId === promoId)
          if (deletedPromoIndex !== -1) {
            this.promoImages.splice(deletedPromoIndex, 1)
          }
          this.$notification.success({
            message: 'Promo image has been successfully deleted from section',
          })
          this.$emit('toggleIsPublished')
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting promo',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
